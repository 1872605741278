import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/performance'
import {
  authStore,
} from '@/store'

interface FirebaseConfig {
  apiKey?: string,
  authDomain?: string,
  databaseURL?: string,
  projectId?: string,
  storageBucket?: string,
  messagingSenderId?: string,
  appId?: string,
  measurementId?: string
}

function loadDevConfig (): FirebaseConfig {
  return {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }
}

async function loadConfig (): Promise<FirebaseConfig> {
  const resp = await fetch(`/__/firebase/init.json`)
  return resp.json()
}

function setupEmulators () {
  const firestore = firebase.firestore()
  const firestoreHost = process.env.VUE_APP_FIREBASE_FIRESTORE_HOST
  const firestorePort = process.env.VUE_APP_FIREBASE_FIRESTORE_PORT
  if (firestoreHost && firestorePort) firestore.useEmulator(String(firestoreHost), Number(firestorePort))
  const functions = firebase.functions()
  const functionsHost = process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST
  const functionsPort = process.env.VUE_APP_FIREBASE_FUNCTIONS_PORT
  if (functionsHost && functionsPort) functions.useEmulator(String(functionsHost), Number(functionsPort))
}

async function init () {
  if (firebase.apps.length) return
  let config: FirebaseConfig
  if (process.env.NODE_ENV === `development`) {
    config = loadDevConfig()
  } else {
    config = await loadConfig()
  }
  // const config = loadDevConfig()
  console.log(`config`, config)

  firebase.initializeApp(config)
  setupEmulators()
  firebase.performance()
  if (process.env.NODE_ENV === `production`) firebase.analytics()
  firebase.auth()
    .onAuthStateChanged(async (user) => {
      authStore.setUser(user)
    })
  // setupEmulators()
  db = firebase.firestore()
  db.enablePersistence()
}

init()

export let db: firebase.firestore.Firestore

export default firebase
