import Vue from 'vue'
import VueRouter, {
  RouteConfig,
} from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    component: Home,
    name: `Home`,
    path: `/`,
  },
  {
    component: () => import(/* webpackChunkName: "signIn" */ `@/views/sign-in/index.vue`),
    name: `SignIn`,
    path: `/sign-in`,
  },
  {
    component: () => import(/* webpackChunkName: "signUp" */ `@/views/sign-up/index.vue`),
    name: `SignUp`,
    path: `/sign-up`,
  },
  {
    component: () => import(/* webpackChunkName: "dashboard" */ `@/views/admin/dashboard/index.vue`),
    name: `Dashboard`,
    path: `/admin`,
  },
  {
    component: () => import(/* webpackChunkName: "places" */ `@/views/admin/places/index.vue`),
    name: `Places`,
    path: `/admin/places`,
  },
  {
    component: () => import(/* webpackChunkName: "placesCreate" */ `@/views/admin/places/create/index.vue`),
    name: `PlacesCreate`,
    path: `/admin/places/create`,
  },
  {
    component: () => import(/* webpackChunkName: "placesEdit" */ `@/views/admin/places/_id/edit/index.vue`),
    name: `PlacesEdit`,
    path: `/admin/places/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "words" */ `@/views/admin/words/index.vue`),
    name: `Words`,
    path: `/admin/words`,
  },
  {
    component: () => import(/* webpackChunkName: "chapters" */ `@/views/admin/chapters/index.vue`),
    name: `Chapters`,
    path: `/admin/chapters`,
  },
  {
    component: () => import(/* webpackChunkName: "chaptersCreate" */ `@/views/admin/chapters/create/index.vue`),
    name: `ChaptersCreate`,
    path: `/admin/chapters/create`,
  },
  {
    component: () => import(/* webpackChunkName: "chaptersEdit" */ `@/views/admin/chapters/_id/edit/index.vue`),
    name: `ChaptersEdit`,
    path: `/admin/chapters/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "lessons" */ `@/views/admin/lessons/index.vue`),
    name: `Lessons`,
    path: `/admin/lessons`,
  },
  {
    component: () => import(/* webpackChunkName: "lessonsCreate" */ `@/views/admin/lessons/create/index.vue`),
    name: `LessonsCreate`,
    path: `/admin/lessons/create`,
  },
  {
    component: () => import(/* webpackChunkName: "lessonsEdit" */ `@/views/admin/lessons/_id/edit/index.vue`),
    name: `LessonsEdit`,
    path: `/admin/lessons/:id/edit`,
  },
  {
    component: () => import(/* webpackChunkName: "grammars" */ `@/views/admin/grammars/index.vue`),
    name: `Grammars`,
    path: `/admin/grammars`,
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: `history`,
  routes,
})

export default router
